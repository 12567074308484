$publicPath:'/tantan-door-admin';











































































































































































































































































































































































































































































































































































































































































































































@import "@/assets/css/global-variable";
.g-role-list-box {
  height: 100%;
  background-color: #fff;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 12px 20px;
}
